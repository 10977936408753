<template>
  <div>
    <CRow>
        <CCol lg="12" style="margin-bottom:10px">
            <CButton v-if="!editable" type="submit" size="sm" color="primary" class="float-right" @click="changeToModifyMode" ><CIcon name="cil-pencil"/> 수정</CButton>        
            <form @submit.prevent="formSubmit" method="post">
            <CButton v-if="editable" type="submit" size="sm" color="primary" class="float-right btn-lft-margin" :disabled="afterClick">
                <CIcon name="cil-pencil"/> 저장
            </CButton>
            </form>
            <CButton v-if="editable" type="submit" size="sm" color="danger" class="float-right" @click="cancelLogMngInfo" ><CIcon name="cil-x-circle"/> 취소</CButton>
            
        </CCol>
    </CRow>
    <CCard class="mb-0">
      <CCardBody>  
          <CDataTable
              hover
              striped
              class="table-align-middle mb-0"
              :items="logMngInfoList"
              :fields="fields"
              no-sorting
              >
              <template #logMngPeriod={item}>
                  <td>
                    <CSelect
                      class="float-center ml-1"
                      style="width: 200px;"
                      :options="$getCodeList('logMngPeriod')"
                      :value.sync="item.logMngPeriod"
                      :disabled="!editable"
                      >
                      
                    </CSelect>
                  </td>
              </template>
              <template #logBackupYn={item}>
                  <td>
                     <CSwitch
                      color="primary"
                      size="lg"
                      class="float-center ml-1"
                      :checked.sync="item.logBackup"
                      labelOn="ON" 
                      labelOff="OFF"
                      :disabled="!editable"
                      />  
                  </td>
              </template>
              <template #logDeleteYn={item}>
                  <td>
                     <CSwitch
                      color="primary"
                      size="lg"
                      class="float-center ml-1"
                      :checked.sync="item.logDelete"
                      labelOn="ON" 
                      labelOff="OFF"
                      :disabled="!editable"
                      />  
                  </td>
              </template>
          </CDataTable>   
      </CCardBody>
            </CCard>
  </div>
</template>
<script>
  export default {
    data() {
      return { 
        fields: [
            { key: 'logMngNm', label: '로그성 데이터 항목'},
            { key: 'logMngPeriod', label: '보관기간'},
            { key: 'logBackupYn', label: '백업 사용 유무'},
            { key: 'logDeleteYn', label: '삭제 사용 유무'}
        ],   
        editable: false, 
        afterClick: false,
        logMngInfoList: [],
      }
    },
    created () {
      this.selectLogMngInfo()
    },
    methods: {
      async selectLogMngInfo(){
        this.loading=true 
        try {
          await this.$store.dispatch('logMngInfo/getLogMngInfoList')          
          this.logMngInfoList = this.$store.state.logMngInfo.logMngInfoList || []
          this.loading=false
        } catch(error){
          this.$response(error)
          this.loading=false
        }
      },
      //저장 버튼 클릭시 동작하는 함수
      async formSubmit(){
          this.afterClick = true      
          this.loading=true 
          try {
              await this.$store.dispatch('logMngInfo/modifyLogMngInfoList', this.logMngInfoList)
              alert("저장하였습니다.")
              this.editable = false
              this.loading=false 
              this.selectLogMngInfo()
          } catch(error){
              this.$response(error)
              this.loading=false
          }
          this.afterClick = false
          return true
      },
      changeToModifyMode() {
          this.editable = true
      },
      cancelLogMngInfo(){
          this.editable = false
          this.selectLogMngInfo()
      }
    },   
  }
</script>